import $api from 'src/utils/api-interface';
import { $api_exporter_v2, $api_ai_v2 } from 'src/utils/apis/v2';
import { createActionAsync } from 'redux-act-async';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import { Dispatch } from 'redux';
import { STATUS_CODES } from 'src/constants/status';
import { omit } from 'lodash';

export const getIntegrationsStatus = createActionAsync('GET_INTEGRATIONS_STATUS', () =>
    $api
        .get({ url: '/integrations' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getServiceNowGroups = createActionAsync('GET_SERVICENOW_GROUPS', () =>
    $api
        .get({ url: '/servicenow/groups' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getServiceNowProblemTaskTypes = createActionAsync('GET_SERVICENOW_PROBLEM_TASK_TYPES', () =>
    $api
        .get({ url: '/servicenow/problem-task/types' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
export const getServiceNowUsers = createActionAsync('GET_SERVICENOW_USERS', () =>
    $api
        .get({ url: '/servicenow/users' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getServiceNowSettings = createActionAsync('GET_SERVICENOW_SETTINGS', () =>
    $api
        .get({ url: '/servicenow/settings' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateServiceNowSettings = createActionAsync(
    'UPDATE_SERVICENOW_SETTINGS',
    (data: Record<string, any>, dispatch: Dispatch) =>
        $api
            .post({ url: '/servicenow/settings', data })
            .then((res) => {
                const { data } = res;
                if (!data.ok) {
                    dispatch(
                        enqueueSnackbar({
                            message: `Problem updating settings: ${data.error_message}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                } else {
                    dispatch(
                        enqueueSnackbar({
                            message: 'Successfully updated ServiceNow settings',
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                }
                return data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getServiceNowMappingFields = createActionAsync('GET_SERVICENOW_MAPPING_FIELDS', () =>
    $api
        .get({ url: '/servicenow/mapping-fields' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getSumoLogicSettings = createActionAsync('GET_SUMOLOGIC_SETTINGS', (tenantId: number) =>
    $api
        .get({ url: '/sumologic/settings', params: { tenant_id: tenantId } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateSumoLogicSettings = createActionAsync(
    'UPDATE_SUMOLOGIC_SETTINGS',
    (data: Record<string, any>, dispatch: Dispatch) =>
        $api
            .put({ url: '/sumologic/settings', data })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Successfully updated sumo logic settings',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getPrometheusSettings = createActionAsync('GET_PROMETHEUS_SETTINGS', () =>
    $api
        .get({ url: '/prometheus/settings' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updatePrometheusSettings = createActionAsync(
    'UPDATE_PROMETHEUS_SETTINGS',
    (data: Record<string, any>, dispatch: Dispatch) =>
        $api
            .put({ url: '/prometheus/settings', data })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Successfully updated prometheus settings',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getSplunkSettings = createActionAsync('GET_SPLUNK_SETTINGS', (tenantId: number) =>
    $api
        .get({ url: '/splunk/settings', params: { tenant_id: tenantId } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateSplunkSettings = createActionAsync(
    'UPDATE_SPLUNK_SETTINGS',
    (data: Record<string, any>, dispatch: Dispatch) =>
        $api
            .put({ url: '/splunk/settings', data })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Successfully updated splunk settings',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getNewrelicSettings = createActionAsync('GET_NEWRELIC_SETTINGS', (tenantId: number) =>
    $api
        .get({ url: '/newrelic/settings', params: { tenant_id: tenantId } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateNewrelicSettings = createActionAsync(
    'UPDATE_NEWRELIC_SETTINGS',
    (data: Record<string, any>, dispatch: Dispatch) =>
        $api
            .put({ url: '/newrelic/settings', data })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Successfully updated new-relic settings',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getDatadogSettings = createActionAsync('GET_DATADOG_SETTINGS', (tenantId: number) =>
    $api
        .get({ url: '/datadog/settings', params: { tenant_id: tenantId } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateDatadogSettings = createActionAsync(
    'UPDATE_DATADOG_SETTINGS',
    (data: Record<string, any>, dispatch: Dispatch) =>
        $api
            .put({ url: '/datadog/settings', data })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Successfully updated datadog settings',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getPingdomSettings = createActionAsync('GET_PINGDOM_SETTINGS', (tenantId: number) =>
    $api
        .get({ url: '/pingdom/settings', params: { tenant_id: tenantId } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updatePingdomSettings = createActionAsync(
    'UPDATE_PINGDOM_SETTINGS',
    (data: Record<string, any>, dispatch: Dispatch) =>
        $api
            .put({ url: '/pingdom/settings', data })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Successfully updated pingdom settings',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

// Google Drive actions
export const getGoogleDriveSettings = createActionAsync('GET_GOOGLE_DRIVE_SETTINGS', () =>
    $api_exporter_v2
        .get({ url: '/settings/google-docs' }, { preventAlertingOnStatusCodes: [STATUS_CODES.NOT_FOUND], retries: 0 })
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status === STATUS_CODES.NOT_FOUND) {
                // If the settings are not found, return undefined
                return undefined;
            } else {
                throw error;
            }
        })
);

export const createGoogleDriveSettings = createActionAsync(
    'CREATE_GOOGLE_DRIVE_SETTINGS',
    (data: Pick<Settings.IGoogleDriveSettings, 'enabled' | 'folderId'>) =>
        $api_exporter_v2
            .post({ url: '/settings/google-docs', data })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const updateGoogleDriveSettings = createActionAsync(
    'UPDATE_GOOGLE_DRIVE_SETTINGS',
    (data: Pick<Settings.IGoogleDriveSettings, 'enabled' | 'folderId'>) =>
        $api_exporter_v2
            .put({ url: '/settings/google-docs', data })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const updateGoogleDriveExportSettings = createActionAsync(
    'UPDATE_GOOGLE_DRIVE_EXPORT_SETTINGS',
    (data: Pick<Settings.IGoogleDriveSettings, 'enabled' | 'folders'>) =>
        $api_exporter_v2
            .put({ url: '/settings/google-docs', data })
            .then((res) => res)
            .catch((error) => {
                throw error;
            })
);

export const getGoogleDriveProbe = createActionAsync('GET_GOOGLE_DRIVE_PROBE', () =>
    $api_exporter_v2
        .get({ url: '/google-docs/probe' })
        .then((res) => res)
        .catch((error) => {
            throw error;
        })
);

// OpenAI actions
export const getGptModels = createActionAsync('GET_GPT_MODELS', () =>
    $api_ai_v2
        .get({ url: '/settings/open-ai/chat/models' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getOpenAiSettings = createActionAsync('GET_OPEN_AI_SETTINGS', () =>
    $api_ai_v2
        .get({ url: '/settings/open-ai' }, { preventAlertingOnStatusCodes: [STATUS_CODES.NOT_FOUND], retries: 0 })
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status === STATUS_CODES.NOT_FOUND) {
                // If the settings are not found, return undefined
                return undefined;
            } else {
                throw error;
            }
        })
);

export const createOpenAiSettings = createActionAsync('CREATE_OPEN_AI_SETTINGS', (data: Settings.IOpenAISettings) =>
    $api_ai_v2
        .post(
            { url: '/settings/open-ai', data },
            { preventAlertingOnStatusCodes: [STATUS_CODES.BAD_REQUEST], retries: 0 }
        )
        .then((res) => res.data)
        .catch((error) => {
            if (error.response.status === STATUS_CODES.BAD_REQUEST) {
                throw new Error('Something failed while creating the OpenAI settings');
            } else {
                throw error;
            }
        })
);

export const updateOpenAiSettings = createActionAsync(
    'UPDATE_OPEN_AI_SETTINGS',
    (data: Partial<Settings.IOpenAISettings>) =>
        $api_ai_v2
            .patch(
                { url: '/settings/open-ai', data },
                { preventAlertingOnStatusCodes: [STATUS_CODES.BAD_REQUEST], retries: 0 }
            )
            .then((res) => res.data)
            .catch((error) => {
                if (error.response.status === STATUS_CODES.BAD_REQUEST) {
                    throw new Error('Something failed while updating the OpenAI settings');
                } else {
                    throw error;
                }
            })
);

export const getOpenAiAudiences = createActionAsync('GET_OPEN_AI_AUDIENCES', () =>
    $api_ai_v2
        .get({ url: '/audiences' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const createOpenAiAudience = createActionAsync(
    'CREATE_NEW_AUDIENCE',
    (data: Partial<Settings.ICommsAssistantAudience>) =>
        $api_ai_v2
            .post({ url: '/audiences', data })
            .then((res) => res)
            .catch((error) => {
                throw error;
            })
);

export const updateOpenAiAudience = createActionAsync('UPDATE_AUDIENCE', (data: Settings.IAudienceForm) =>
    $api_ai_v2
        .put({
            url: `/audiences/${data.id}`,
            data: data.default ? omit(data, 'id', 'default', 'prompt') : omit(data, 'id', 'default', 'channels')
        })
        .then((res) => res)
        .catch((error) => {
            throw error;
        })
);

export const deleteOpenAiAudience = createActionAsync('DELETE_AUDIENCE', (id: string) =>
    $api_ai_v2
        .delete({ url: `/audiences/${id}` })
        .then((res) => res)
        .catch((error) => {
            throw error;
        })
);

export const getOpenAiAudienceChannels = createActionAsync('GET_AUDIENCE_CHANNELS', (audienceId: string) =>
    $api
        .get({ url: `/comms-assistant/channels/${audienceId}` })
        .then((res) => res)
        .catch((error) => {
            throw error;
        })
);

export const updateOpenAiAudienceChannels = createActionAsync(
    'UPDATE_AUDIENCE_CHANNELS',
    (audienceFormData: Settings.IAudienceForm) =>
        $api
            .put({
                url: `/comms-assistant/channels/${audienceFormData.id}`,
                data: {
                    channels: audienceFormData.channels
                }
            })
            .then((res) => res)
            .catch((error) => {
                throw error;
            })
);
