import { enableMapSet, setAutoFreeze } from 'immer';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createReduxEnhancer } from '@sentry/react';
import { deDuplicateAsyncRequest, determineIfOnboardingNeeded, errorLoggingMiddleware } from 'src/store/middleware';
setAutoFreeze(false); // Prevent immer to freeze state trees
enableMapSet(); // To enable Immer to operate on the native Map and Set collections

import ldRedux from 'ld-redux';
import ReduxThunk from 'redux-thunk';
import history from 'src/history';
import reducers from 'src/reducers';

const rootReducer = reducers(history);
const sentryReduxEnhancer = createReduxEnhancer();
const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(ReduxThunk, errorLoggingMiddleware, deDuplicateAsyncRequest, determineIfOnboardingNeeded),
        sentryReduxEnhancer
    )
);

const flags = {
    onboarding: false,
    'jira-labels': false,
    'slos-enabled': false,
    'workflows-m-1': false,
    'pm-email-lists': false,
    'standalone-slo': false,
    'jira-priorities': false,
    'enable-comments': false,
    'enable-runbooks': false,
    'runbooks-advanced-mode': false,
    'snow-integration': false,
    'enable-slo-stats': false,
    'slo-blameless-api': false,
    'use-business-days': false,
    'enable-slo-wizard': false,
    'integration-probes': false,
    'pingdom-integration': false,
    'slo-preview-feature': false,
    'datadog-integration': false,
    'enable-ri-demo-data': false,
    'newrelic-integration': false,
    'postmortem-list-page': false,
    'enable-comms-flow-ui': false,
    'auto-task-integration': false,
    'enable-slo-alpha-trial': false,
    'user-specific-timezone': false,
    'display-eb-extra-fields': false,
    'app-dynamics-integration': false,
    'enable-slack-user-groups': false,
    'jira-settings-by-type-sev': false,
    'enable-incident-assistant': false,
    'enable-incident-briefs': false,
    'incident-dashboard-enabled': false,
    'enable-real-time-messaging': false,
    'enable-throughput-sli-type': false,
    'enable-saturation-sli-type': false,
    'enable-dev-dashboard-topics': false,
    'onboarding-terms-of-service': false,
    'enable-comms-flow-logs-menu': false,
    'enable-storage-integrations': false,
    'enable-editing-collaborators': false,
    'enable-new-google-drive-auth': false,
    'workflows-invite-users-action': false,
    'workflows-trigger-alert-action': false,
    'enable-identity-management-page': false,
    'enable-comms-flow-basic-filters': false,
    'enable-comms-flow-manual-trigger': false,
    'workflows-trigger-webhook-action': false,
    'enable-comms-flow-global-variables': false,
    'enable-comms-flow-reminder-trigger': false,
    'enable-comms-flow-advanced-filters': false,
    'enable-create-standalone-postmortem': false,
    'enable-multiple-error-budget-policies': false,
    'workflows-send-sms-communication-action': false,
    'enable-new-error-budget-portal-page-flag': false,
    'workflows-set-retrospective-state-action': false,
    'workflows-send-email-communication-action': false,
    'workflows-send-slack-communication-action': false,
    'enable-comms-flow-incident-channel-checkbox': false,
    'workflows-send-ms-team-communication-action': false,
    'enable-audit-log': false,
    github: false,
    'disable-ri-grouping': false,
    'ms-teams-poc': false,
    'hide-slack-integration-settings-ui': false,
    'teams-meetings-integration': false,
    use_mt_statuspage: false,
    'enable-comms-flow-roles-slack': false,
    'enable-comms-flow-roles-ms-teams': false,
    'enable-comms-flow-roles-email': false,
    'enable-comms-flow-roles-sms': false,
    'enable-comms-flow-statuspage': false,
    'merge-pagerduty-hooks': false,
    'pagerduty-v3-migration': false,
    'enable-grafana-prometheus-mode': false,
    'enable-jira-oauth': false,
    'sli-splunk-integration': false,
    'sli-sumo-logic-integration': false,
    'pager-duty-on-incident-create': false,
    'pd-default-alert-requester': false,
    'enable-snow-oauth': false,
    'enable-tag-management': false,
    'enable-snow-service-assignment': false,
    'enable-incident-type-edit': false,
    'enable-slack-oauth-flow': false,
    'enable-token-management': false,
    'enable-free-trial-welcome-page': false,
    'enable-slack-setup-reminder-popup': false,
    'enable-opsgenie-service-assignment': false,
    'enable-self-trial-on-boarding-flow': false,
    'enable-incident-type-settings-refresh': false,
    'custom-retrospective-tab': false,
    'enable-snow-problem-creation': false,
    'enable-tag-added-event-type': false,
    'enable-new-fua': false,
    'enable-incident-first-actions': false,
    'enable-configuration-service': false,
    'reliability-insights-tile-field-limit': 10
};

export type LaunchDarklyState = {
    onboarding: boolean;
    enableRunbooks: boolean;
    jiraLabels: boolean;
    workflowsM1: boolean;
    slosEnabled: boolean;
    pmEmailLists: boolean;
    standaloneSlo: boolean;
    jiraPriorities: boolean;
    enableSloStats: boolean;
    runbooksAdvancedMode: boolean;
    snowIntegration: boolean;
    sloBlamelessApi: boolean;
    useBusinessDays: boolean;
    enableSloWizard: boolean;
    enableRiDemoData: boolean;
    integrationProbes: boolean;
    sloPreviewFeature: boolean;
    enableCommsFlowUi: boolean;
    pingdomIntegration: boolean;
    postmortemListPage: boolean;
    datadogIntegration: boolean;
    newrelicIntegration: boolean;
    autoTaskIntegration: boolean;
    enableSloAlphaTrial: boolean;
    userSpecificTimezone: boolean;
    displayEbExtraFields: boolean;
    enableSlackUserGroups: boolean;
    jiraSettingsByTypeSev: boolean;
    appDynamicsIntegration: boolean;
    enableCommsFlowLogsMenu: boolean;
    enableRealTimeMessaging: boolean;
    enableThroughputSliType: boolean;
    enableSaturationSliType: boolean;
    enableIncidentAssistant: boolean;
    enableIncidentBriefs: boolean;
    incidentDashboardEnabled: boolean;
    enableDevDashboardTopics: boolean;
    onboardingTermsOfService: boolean;
    enableNewGoogleDriveAuth: boolean;
    enableStorageIntegrations: boolean;
    enableEditingCollaborators: boolean;
    workflowsInviteUsersAction: boolean;
    enableCommsFlowBasicFilters: boolean;
    workflowsTriggerAlertAction: boolean;
    enableIdentityManagementPage: boolean;
    enableCommsFlowManualTrigger: boolean;
    workflowsTriggerWebhookAction: boolean;
    enableCommsFlowGlobalVariables: boolean;
    enableCommsFlowReminderTrigger: boolean;
    enableCommsFlowAdvancedFilters: boolean;
    enableCreateStandalonePostmortem: boolean;
    enableMultipleErrorBudgetPolicies: boolean;
    enableNewErrorBudgetPortalPageFlag: boolean;
    workflowsSendSmsCommunicationAction: boolean;
    workflowsSetRetrospectiveStateAction: boolean;
    workflowsSendEmailCommunicationAction: boolean;
    workflowsSendSlackCommunicationAction: boolean;
    enableCommsFlowIncidentChannelCheckbox: boolean;
    workflowsSendMsTeamCommunicationAction: boolean;
    enableComments: boolean;
    enableAuditLog: boolean;
    github: boolean;
    disableRiGrouping: boolean;
    msTeamsPoc: boolean;
    hideSlackIntegrationSettingsUi: boolean;
    teamsMeetingsIntegration: boolean;
    useMtStatuspage: boolean;
    enableCommsFlowRolesSlack: boolean;
    enableCommsFlowRolesMsTeams: boolean;
    enableCommsFlowRolesEmail: boolean;
    enableCommsFlowRolesSms: boolean;
    enableCommsFlowStatuspage: boolean;
    mergePagerdutyHooks: boolean;
    pagerdutyV3Migration: boolean;
    enableGrafanaPrometheusMode: boolean;
    enableJiraOauth: boolean;
    sliSplunkIntegration: boolean;
    sliSumoLogicIntegration: boolean;
    pagerDutyOnIncidentCreate: boolean;
    pdDefaultAlertRequester: boolean;
    enableSnowOauth: boolean;
    enableTagManagement: boolean;
    enableSnowServiceAssignment: boolean;
    enableIncidentTypeEdit: boolean;
    enableSlackOauthFlow: boolean;
    enableTokenManagement: boolean;
    enableFreeTrialWelcomePage: boolean;
    enableSlackSetupReminderPopup: boolean;
    enableOpsgenieServiceAssignment: boolean;
    enableSelfTrialOnBoardingFlow: boolean;
    enableIncidentTypeSettingsRefresh: boolean;
    customRetrospectiveTab: boolean;
    enableSnowProblemCreation: boolean;
    enableTagAddedEventType: boolean;
    enableNewFua: boolean;
    enableIncidentFirstActions: boolean;
    enableConfigurationService: boolean;
    reliabilityInsightsTileFieldLimit: number;
};

const user = {
    key: window.location.hostname,
    custom: {
        hostname: window.location.hostname,
        groups: 'beta'
    }
};

ldRedux.init({
    clientSideId: '5b75bf4e40fd9d163928ca69',
    dispatch: store.dispatch,
    flags,
    user
});

export default store;
export type RootState = Omit<ReturnType<typeof rootReducer>, 'launchDarkly'> & {
    launchDarkly: LaunchDarklyState;
};
