import merge from 'lodash/merge';
import {
    addIncidentEvent,
    addIncidentTask,
    addIncidentMeeting,
    deleteIncidentTask,
    deleteIncidentTimelineEvent,
    deleteMemberFromTeam,
    errorExportingPDF,
    exportIncidentAsEmail,
    exportIncidentAsMarkdown,
    exportIncidentAsPdf,
    getIncidentCommsChannels,
    getIncidentDetails,
    getIncidentRoles,
    getIncidentSeverities,
    getCategoriesForIncident,
    getIncidentTasks,
    getMoreIncidentsTasks,
    getIncidentTimeline,
    getIncidentTypes,
    getSlackChannelsAndIncidentChannel,
    getStatuspageIncidents,
    getStatuspageComponents,
    setIncidentValue,
    sseAddUpdateTasks,
    sseCreateTask,
    sseCreateTimelineEvent,
    sseDeleteMemberFromTeam,
    sseDeleteTask,
    sseDeleteTimelineEvent,
    sseUpdateIncident,
    sseUpdateTask,
    sseUpdateTimelineEvent,
    updateIncidentEvent,
    updateIncidentTask,
    updateIncidentTasks,
    updateIncidentTicket,
    updateIncidentTimeline,
    addPredefinedTagToIncident,
    addFreeFormTagToIncident,
    deleteTagFromIncident,
    updateIncidentAssistantSettings,
    getIncidentAssistantSettings,
    getIncidentPermissions,
    updateIncidentPermission
} from 'src/actions/incidents/incident-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    exportLoadStatus: string;
    timelineDeleteEventStatus: string;
    actionsLoadStatus: string;
    incidentLoadStatus: string;
    incidentRolesLoadStatus: string;
    incidentTicketLoadStatus: string;
    incidentMeetingStatus: string;
    setIncidentStatus: string;
    timelineLoadStatus: string;
    timelineAddEventStatus: string;
    timelineUpdateEventStatus: string;
    tasksLoadStatus: string;
    tasksUpdatingStatus: string;
    addCommsLeadStatus: string;
    updateCommsLeadStatus: string;
    publishCommsLeadStatus: string;
    channelsLoadStatus: string;
    deleteTeamMemberStatus: string;
    getIncidentCommsChannelsLoadStatus: string;
    getStatuspageIncidentsLoadStatus: string;
    getStatuspageComponentsLoadStatus: string;
    incident: null | IIncident.IIncident;
    actions: any[];
    errorMessage: string;
    successMessage: string;
    timelineEvents: any[];
    tasks: IIncident.ITask[];
    incidentRoles: IIncident.IRole[];
    commsChannels: IIncident.ICommsChannels;
    statuspageIncidents: any[];
    statuspageComponents: Statuspage.Component[];
    incidentTypes: IIncident.IIncidentTypes;
    getIncidentTypesLoadStatus: string;
    incidentSeverities: IIncident.ISeverity[];
    getIncidentSeveritiesLoadStatus: string;
    tagCategoriesForIncident: Record<string, Record<string, IIncident.ICategory>>;
    getCategoriesForIncidentLoadStatus: string;
    addPredefinedTagToIncidentLoadStatus: string;
    addFreeFormTagToIncidentLoadStatus: string;
    deleteTagFromIncidentLoadStatus: string;
    slackChannels: ISlack.ISlackChannel[];
    integrations: IIncident.IIntegrations;
    impactedServices: IIncident.IImpactedService[];
    getMoreTasksLoadStatus: string;
    tasksCount: number;
    getIncidentAssistantSettingsLoadStatus: string;
    updateIncidentAssistantSettingsLoadStatus: string;
    incidentAssistantSettings: IIncident.IIncidentAssistantSettings;
    incidentPermissions: IIncident.IIncidentPermissions;
    getIncidentPermissionsLoadStatus: string;
}

export const defaultState: IDefaultState = {
    exportLoadStatus: LoadStatus.EMPTY,
    timelineDeleteEventStatus: LoadStatus.EMPTY,
    actionsLoadStatus: LoadStatus.EMPTY,
    incidentLoadStatus: LoadStatus.EMPTY,
    incidentRolesLoadStatus: LoadStatus.EMPTY,
    incidentTicketLoadStatus: LoadStatus.EMPTY,
    incidentMeetingStatus: LoadStatus.EMPTY,
    setIncidentStatus: LoadStatus.EMPTY,
    timelineLoadStatus: LoadStatus.EMPTY,
    timelineAddEventStatus: LoadStatus.EMPTY,
    timelineUpdateEventStatus: LoadStatus.EMPTY,
    tasksLoadStatus: LoadStatus.EMPTY,
    getMoreTasksLoadStatus: LoadStatus.EMPTY,
    tasksUpdatingStatus: LoadStatus.EMPTY,
    addCommsLeadStatus: LoadStatus.EMPTY,
    updateCommsLeadStatus: LoadStatus.EMPTY,
    publishCommsLeadStatus: LoadStatus.EMPTY,
    channelsLoadStatus: LoadStatus.EMPTY,
    deleteTeamMemberStatus: LoadStatus.EMPTY,
    getIncidentCommsChannelsLoadStatus: LoadStatus.EMPTY,
    getStatuspageIncidentsLoadStatus: LoadStatus.EMPTY,
    getStatuspageComponentsLoadStatus: LoadStatus.EMPTY,
    addPredefinedTagToIncidentLoadStatus: LoadStatus.EMPTY,
    addFreeFormTagToIncidentLoadStatus: LoadStatus.EMPTY,
    deleteTagFromIncidentLoadStatus: LoadStatus.EMPTY,
    incident: null,
    actions: [],
    errorMessage: '',
    successMessage: '',
    timelineEvents: [],
    tasks: [],
    tasksCount: 0,
    incidentRoles: [],
    commsChannels: { internal: [], external: [] },
    slackChannels: [],
    statuspageIncidents: [],
    statuspageComponents: [],
    incidentTypes: {
        read: [],
        create: [],
        update: [],
        delete: []
    } as IIncident.IIncidentTypes,
    getIncidentTypesLoadStatus: LoadStatus.EMPTY,
    incidentSeverities: [],
    getIncidentSeveritiesLoadStatus: LoadStatus.EMPTY,
    tagCategoriesForIncident: {},
    getCategoriesForIncidentLoadStatus: LoadStatus.EMPTY,
    integrations: {} as IIncident.IIntegrations,
    impactedServices: [],
    getIncidentAssistantSettingsLoadStatus: LoadStatus.EMPTY,
    updateIncidentAssistantSettingsLoadStatus: LoadStatus.EMPTY,
    incidentAssistantSettings: {
        enabled: false
    },
    incidentPermissions: {
        type: [],
        incident: [],
        noAccess: []
    },
    getIncidentPermissionsLoadStatus: LoadStatus.EMPTY
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case sseUpdateIncident.getType():
                if (!draft.incident || draft.incident._id == action.payload.incident._id) {
                    draft.incident = {
                        ...draft.incident,
                        ...action.payload.incident
                    };
                    return;
                }
                return;
            case sseUpdateTimelineEvent.getType():
                draft.timelineEvents = draft.timelineEvents.map((timelineEvent) => {
                    if (timelineEvent._id == action.payload.timelineEvent._id) {
                        return {
                            ...timelineEvent,
                            ...action.payload.timelineEvent
                        };
                    }
                    return timelineEvent;
                });
                return;
            case sseCreateTimelineEvent.getType():
                const matchingEvents = draft.timelineEvents.filter(
                    (event) => event._id === action.payload.timelineEvent._id
                );

                if (!matchingEvents.length) {
                    draft.timelineEvents = [...draft.timelineEvents, action.payload.timelineEvent].sort((a, b) =>
                        a.date['$date'] < b.date['$date'] ? -1 : 1
                    );
                }
                return;
            case sseDeleteTimelineEvent.getType():
                draft.timelineEvents = draft.timelineEvents.filter(
                    (event) => event._id !== action.payload.timelineEvent._id
                );
                return;
            case sseDeleteMemberFromTeam.getType():
                if (draft.incident) {
                    draft.incident.team = draft.incident.team.filter((user) =>
                        action.payload.response.team.includes(user._id)
                    );
                }
                return;
            case sseCreateTask.getType():
                draft.tasks = [...draft.tasks.filter((a) => a._id != action.payload.task._id), action.payload.task];
                return;
            case sseUpdateTask.getType():
                draft.tasks = draft.tasks.map((task) => {
                    if (task._id === action.payload.task._id) {
                        return { ...task, ...action.payload.task };
                    }
                    return task;
                });
                return;
            case sseDeleteTask.getType():
                draft.tasks = draft.tasks.filter((task) => task._id !== action.payload.task._id);
                return;
            case sseAddUpdateTasks.getType():
                const updatedTasksById = action.payload.updatedTasks
                    ? action.payload.updatedTasks.map((task) => {
                          return { _id: task };
                      })
                    : {};
                const updatedTasks = draft.tasks.map((task) => {
                    if (updatedTasksById[task._id]) {
                        return { ...task, ...updatedTasksById[task._id] };
                    }
                    return task;
                });
                draft.tasks = updatedTasks.concat(action.payload.createdTasks);
                return;
            case updateIncidentTicket.request.getType():
                draft.incidentTicketLoadStatus = LoadStatus.REQUEST;
                return;
            case updateIncidentTicket.ok.getType():
                draft.incident = action.payload.response.incident;
                draft.integrations = action.payload.response.integrations;
                draft.incidentTicketLoadStatus = LoadStatus.OK;
                return;
            case updateIncidentTicket.error.getType():
                draft.incidentTicketLoadStatus = LoadStatus.ERROR;
                draft.errorMessage = action.payload.error.response.data.message;
                return;
            case getSlackChannelsAndIncidentChannel.request.getType():
                draft.channelsLoadStatus = LoadStatus.REQUEST;
                return;
            case getSlackChannelsAndIncidentChannel.ok.getType():
                draft.slackChannels = action.payload.response.slack_channels;
                draft.channelsLoadStatus = LoadStatus.OK;
                return;
            case getSlackChannelsAndIncidentChannel.error.getType():
                draft.channelsLoadStatus = LoadStatus.ERROR;
                return;
            case getIncidentRoles.request.getType():
                draft.incidentRolesLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentRoles.ok.getType():
                draft.incidentRoles = action.payload.response['roles'];
                draft.incidentRolesLoadStatus = LoadStatus.OK;
                return;
            case getIncidentRoles.error.getType():
                draft.incidentRolesLoadStatus = LoadStatus.ERROR;
                return;
            case setIncidentValue.request.getType():
                draft.setIncidentStatus = LoadStatus.REQUEST;
                return;
            case setIncidentValue.ok.getType():
                if (action.payload.response?.incident) {
                    draft.incident = action.payload.response.incident;
                }
                if (action.payload.response?.events) {
                    draft.timelineEvents = action.payload.response.events;
                }
                draft.setIncidentStatus = LoadStatus.OK;
                return;
            case setIncidentValue.error.getType():
                draft.setIncidentStatus = LoadStatus.ERROR;
                return;
            case getIncidentDetails.request.getType():
                draft.incidentLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentDetails.ok.getType():
                const sameIncident = draft.incident && draft.incident._id == action.payload.response.incident._id;
                draft.incident = sameIncident
                    ? merge({}, draft.incident, action.payload.response.incident)
                    : action.payload.response.incident;
                draft.integrations = action.payload.response.integrations;
                draft.incidentLoadStatus = LoadStatus.OK;
                return;
            case getIncidentDetails.error.getType():
                draft.incidentLoadStatus = LoadStatus.ERROR;
                return;
            case exportIncidentAsMarkdown.request.getType():
                draft.exportLoadStatus = LoadStatus.REQUEST;
                return;
            case exportIncidentAsMarkdown.ok.getType():
                draft.exportLoadStatus = LoadStatus.OK;
                return;
            case exportIncidentAsMarkdown.error.getType():
                draft.exportLoadStatus = LoadStatus.ERROR;
                return;
            case exportIncidentAsEmail.request.getType():
                draft.exportLoadStatus = LoadStatus.REQUEST;
                return;
            case exportIncidentAsEmail.ok.getType():
                draft.exportLoadStatus = LoadStatus.OK;
                return;
            case exportIncidentAsEmail.error.getType():
                draft.exportLoadStatus = LoadStatus.ERROR;
                return;
            case getIncidentCommsChannels.request.getType():
                draft.getIncidentCommsChannelsLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentCommsChannels.ok.getType():
                draft.commsChannels = action.payload.response.channels;
                draft.getIncidentCommsChannelsLoadStatus = LoadStatus.OK;
                return;
            case getIncidentCommsChannels.error.getType():
                draft.getIncidentCommsChannelsLoadStatus = LoadStatus.ERROR;
                return;
            case getIncidentTimeline.request.getType():
                draft.timelineLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentTimeline.ok.getType():
                draft.timelineEvents = action.payload.response.events;
                draft.timelineLoadStatus = LoadStatus.OK;
                return;
            case getIncidentTimeline.error.getType():
                draft.timelineLoadStatus = LoadStatus.ERROR;
                return;
            case deleteIncidentTimelineEvent.request.getType():
                draft.timelineDeleteEventStatus = LoadStatus.REQUEST;
                return;
            case deleteIncidentTimelineEvent.ok.getType():
                draft.timelineEvents = draft.timelineEvents.filter(
                    (event) => event._id !== action.payload.response.event._id
                );
                draft.timelineDeleteEventStatus = LoadStatus.OK;
                return;
            case deleteIncidentTimelineEvent.error.getType():
                draft.timelineDeleteEventStatus = LoadStatus.ERROR;
                return;
            case getIncidentTasks.request.getType():
                draft.tasksLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentTasks.ok.getType():
                draft.tasks = action.payload.response.tasks;
                draft.tasksCount = action.payload.response.pagination.count;
                draft.tasksLoadStatus = LoadStatus.OK;
                return;
            case getIncidentTasks.error.getType():
                draft.tasksLoadStatus = LoadStatus.ERROR;
                return;

            case getMoreIncidentsTasks.request.getType():
                draft.getMoreTasksLoadStatus = LoadStatus.REQUEST;
                return;
            case getMoreIncidentsTasks.ok.getType():
                draft.tasks = action.payload.response.tasks;
                draft.getMoreTasksLoadStatus = action.payload.response.pagination.count;
                draft.tasks = state.tasks.concat(action.payload.response.tasks);
                return;
            case getMoreIncidentsTasks.error.getType():
                draft.getMoreTasksLoadStatus = LoadStatus.ERROR;
                return;
            case addIncidentEvent.request.getType():
                draft.timelineAddEventStatus = LoadStatus.REQUEST;
                return;
            case addIncidentEvent.ok.getType():
                draft.timelineEvents = action.payload.response.events;
                draft.timelineAddEventStatus = LoadStatus.OK;
                return;
            case addIncidentEvent.error.getType():
                draft.timelineAddEventStatus = LoadStatus.ERROR;
                return;
            case addIncidentMeeting.request.getType():
                draft.incidentMeetingStatus = LoadStatus.REQUEST;
                return;
            case addIncidentMeeting.ok.getType():
                draft.incident = action.payload.response.incident;
                draft.incidentMeetingStatus = LoadStatus.OK;
                return;
            case addIncidentMeeting.error.getType():
                draft.incidentMeetingStatus = LoadStatus.ERROR;
                return;
            case updateIncidentEvent.request.getType():
                draft.timelineUpdateEventStatus = LoadStatus.REQUEST;
                return;
            case updateIncidentEvent.ok.getType():
                draft.timelineEvents = draft.timelineEvents.map((event) => {
                    if (event._id === action.payload.response.event._id) {
                        return {
                            source: {
                                profile: {}
                            },
                            ...action.payload.response.event
                        };
                    }
                    return event;
                });
                draft.timelineUpdateEventStatus = LoadStatus.OK;
                return;
            case updateIncidentEvent.error.getType():
                draft.timelineUpdateEventStatus = LoadStatus.ERROR;
                return;
            case addIncidentTask.request.getType():
                draft.tasksUpdatingStatus = LoadStatus.REQUEST;
                return;
            case addIncidentTask.ok.getType():
                (draft.tasks = [
                    ...draft.tasks.filter((a) => a._id != action.payload.response.task._id),
                    action.payload.response.task
                ]),
                    (draft.tasksUpdatingStatus = LoadStatus.OK);
                return;
            case addIncidentTask.error.getType():
                draft.tasksUpdatingStatus = LoadStatus.ERROR;
                return;
            case updateIncidentTask.request.getType():
                draft.tasksUpdatingStatus = LoadStatus.REQUEST;
                return;
            case updateIncidentTask.ok.getType():
                (draft.tasks = draft.tasks.map((task) => {
                    if (task._id === action.payload.response.task._id) {
                        return action.payload.response.task;
                    }
                    return task;
                })),
                    (draft.tasksUpdatingStatus = LoadStatus.OK);
                return;
            case updateIncidentTask.error.getType():
                draft.tasksUpdatingStatus = LoadStatus.ERROR;
                return;
            case updateIncidentTasks.request.getType():
                draft.tasksUpdatingStatus = LoadStatus.REQUEST;
                return;
            case updateIncidentTasks.ok.getType():
                draft.tasks = action.payload.response.tasks.length
                    ? draft.tasks.map((task) => {
                          const newTask = action.payload.response.tasks.find((t) => t._id === task._id);
                          if (newTask) {
                              return {
                                  ...task,
                                  order_number: newTask.order_number
                              };
                          }
                          return task;
                      })
                    : draft.tasks;
                draft.tasksUpdatingStatus = LoadStatus.OK;
                return;
            case updateIncidentTasks.error.getType():
                draft.tasksUpdatingStatus = LoadStatus.ERROR;
                return;
            case deleteIncidentTask.request.getType():
                draft.tasksUpdatingStatus = LoadStatus.REQUEST;
                return;
            case deleteIncidentTask.ok.getType():
                (draft.tasks = draft.tasks.filter((t) => t._id !== action.payload.response.task._id)),
                    (draft.tasksUpdatingStatus = LoadStatus.OK);
                return;
            case deleteIncidentTask.error.getType():
                draft.tasksUpdatingStatus = LoadStatus.ERROR;
                return;
            case deleteMemberFromTeam.request.getType():
                draft.deleteTeamMemberStatus = LoadStatus.REQUEST;
                return;
            case deleteMemberFromTeam.ok.getType():
                if (draft.incident) {
                    draft.incident.team = draft.incident.team.filter((user) =>
                        action.payload.response.team.includes(user._id)
                    );
                }
                draft.deleteTeamMemberStatus = LoadStatus.OK;
                return;
            case deleteMemberFromTeam.error.getType():
                draft.deleteTeamMemberStatus = LoadStatus.ERROR;
                return;
            case getIncidentTypes.request.getType():
                draft.getIncidentTypesLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentTypes.ok.getType():
                if (action.payload.response.forbidden) {
                    draft.incidentTypes[action.payload.response.action] = [];
                } else {
                    draft.incidentTypes[action.payload.response.action] = action.payload.response.incidentTypes.sort(
                        (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
                    );
                }
                draft.getIncidentTypesLoadStatus = LoadStatus.OK;
                return;
            case getIncidentTypes.error.getType():
                draft.getIncidentTypesLoadStatus = LoadStatus.ERROR;
                return;
            case getIncidentSeverities.request.getType():
                draft.getIncidentSeveritiesLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentSeverities.ok.getType():
                draft.incidentSeverities = action.payload.response.severities;
                draft.getIncidentSeveritiesLoadStatus = LoadStatus.OK;
                return;
            case getIncidentSeverities.error.getType():
                draft.getIncidentSeveritiesLoadStatus = LoadStatus.ERROR;
                return;
            case getCategoriesForIncident.request.getType():
                draft.getCategoriesForIncidentLoadStatus = LoadStatus.REQUEST;
                return;
            case getCategoriesForIncident.ok.getType():
                draft.tagCategoriesForIncident[action.payload.request['0']] = action.payload.response;
                draft.getCategoriesForIncidentLoadStatus = LoadStatus.OK;
                return;
            case getCategoriesForIncident.error.getType():
                draft.getCategoriesForIncidentLoadStatus = LoadStatus.ERROR;
                return;
            case updateIncidentTimeline.getType():
                draft.timelineEvents = action.payload.response.events;
                return;
            case getStatuspageIncidents.request.getType():
                draft.getStatuspageIncidentsLoadStatus = LoadStatus.REQUEST;
                return;
            case getStatuspageIncidents.ok.getType():
                draft.statuspageIncidents = action.payload.response.incidents;
                draft.getStatuspageIncidentsLoadStatus = LoadStatus.OK;
                return;
            case getStatuspageIncidents.error.getType():
                draft.getStatuspageIncidentsLoadStatus = LoadStatus.ERROR;
                return;
            case getStatuspageComponents.request.getType():
                draft.getStatuspageComponentsLoadStatus = LoadStatus.REQUEST;
                return;
            case getStatuspageComponents.ok.getType():
                draft.statuspageComponents = action.payload.response.components;
                draft.getStatuspageComponentsLoadStatus = LoadStatus.OK;
                return;
            case getStatuspageComponents.error.getType():
                draft.getStatuspageComponentsLoadStatus = LoadStatus.ERROR;
                return;
            case exportIncidentAsPdf.request.getType():
                draft.exportLoadStatus = LoadStatus.REQUEST;
                return;
            case exportIncidentAsPdf.ok.getType():
                draft.exportLoadStatus = LoadStatus.OK;
                return;
            case exportIncidentAsPdf.error.getType():
                draft.exportLoadStatus = LoadStatus.ERROR;
                return;
            case errorExportingPDF.getType():
                draft.exportLoadStatus = LoadStatus.ERROR;
                return;
            case addPredefinedTagToIncident.ok.getType():
                draft.tagCategoriesForIncident[action.payload.request['0']] = action.payload.response;
                draft.addPredefinedTagToIncidentLoadStatus = LoadStatus.OK;
                return;
            case addPredefinedTagToIncident.error.getType():
                draft.addPredefinedTagToIncidentLoadStatus = LoadStatus.ERROR;
                return;
            case addPredefinedTagToIncident.request.getType():
                draft.addFreeFormTagToIncidentLoadStatus = LoadStatus.REQUEST;
                return;
            case addFreeFormTagToIncident.ok.getType():
                draft.tagCategoriesForIncident[action.payload.request['0']] = action.payload.response;
                draft.addFreeFormTagToIncidentLoadStatus = LoadStatus.OK;
                return;
            case addFreeFormTagToIncident.error.getType():
                draft.addFreeFormTagToIncidentLoadStatus = LoadStatus.ERROR;
                return;
            case addFreeFormTagToIncident.request.getType():
                draft.addFreeFormTagToIncidentLoadStatus = LoadStatus.REQUEST;
                return;
            case deleteTagFromIncident.ok.getType():
                draft.tagCategoriesForIncident[action.payload.request['0']] = action.payload.response;
                draft.deleteTagFromIncidentLoadStatus = LoadStatus.OK;
                return;
            case deleteTagFromIncident.error.getType():
                draft.deleteTagFromIncidentLoadStatus = LoadStatus.ERROR;
                return;
            case deleteTagFromIncident.request.getType():
                draft.deleteTagFromIncidentLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentAssistantSettings.ok.getType():
                draft.incidentAssistantSettings = action.payload.response;
                draft.getIncidentAssistantSettingsLoadStatus = LoadStatus.OK;
                return;
            case getIncidentAssistantSettings.error.getType():
                draft.getIncidentAssistantSettingsLoadStatus = LoadStatus.ERROR;
                return;
            case getIncidentAssistantSettings.request.getType():
                draft.getIncidentAssistantSettingsLoadStatus = LoadStatus.REQUEST;
                return;
            case updateIncidentAssistantSettings.ok.getType():
                const [isIncidentAssistantEnabled] = action.payload.request;
                draft.incidentAssistantSettings = {
                    ...draft.incidentAssistantSettings,
                    enabled: isIncidentAssistantEnabled
                };
                draft.updateIncidentAssistantSettingsLoadStatus = LoadStatus.OK;
                return;
            case updateIncidentAssistantSettings.error.getType():
                draft.updateIncidentAssistantSettingsLoadStatus = LoadStatus.ERROR;
                return;
            case updateIncidentAssistantSettings.request.getType():
                draft.updateIncidentAssistantSettingsLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentPermissions.ok.getType():
                draft.incidentPermissions = action.payload.response;
                draft.getIncidentPermissionsLoadStatus = LoadStatus.OK;
                return;
            case getIncidentPermissions.error.getType():
                draft.getIncidentPermissionsLoadStatus = LoadStatus.ERROR;
                return;
            case getIncidentPermissions.request.getType():
                draft.getIncidentPermissionsLoadStatus = LoadStatus.REQUEST;
                return;
            case updateIncidentPermission.ok.getType():
                draft.incidentPermissions = action.payload.response;
                return;
        }
    });
}
